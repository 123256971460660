import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Trans } from 'react-i18next';

const Sidebar = () => {
  const location = useLocation();
  const [state, setState] = useState({});

  const toggleMenuState = (menuState) => {
    if (state[menuState]) {
      setState({...state, [menuState]: false});
    } else if(Object.keys(state).length === 0) {
      setState({[menuState]: true});
    } else {
      Object.keys(state).forEach(i => {
        setState(prev => ({...prev, [i]: false}));
      });
      setState(prev => ({...prev, [menuState]: true}));
    }
  };

  const onRouteChanged = () => {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(state).forEach(i => {
      setState(prev => ({...prev, [i]: false}));
    });

    const dropdownPaths = [
      {path:'/apps', state: 'appsMenuOpen'},
      {path:'/basic-ui', state: 'basicUiMenuOpen'},
      {path:'/advanced-ui', state: 'advancedUiMenuOpen'},
      {path:'/form-elements', state: 'formElementsMenuOpen'},
      {path:'/tables', state: 'tablesMenuOpen'},
      {path:'/maps', state: 'mapsMenuOpen'},
      {path:'/icons', state: 'iconsMenuOpen'},
      {path:'/charts', state: 'chartsMenuOpen'},
      {path:'/user-pages', state: 'userPagesMenuOpen'},
      {path:'/error-pages', state: 'errorPagesMenuOpen'},
      {path:'/general-pages', state: 'generalPagesMenuOpen'},
      {path:'/ecommerce', state: 'ecommercePagesMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      if (isPathActive(obj.path)) {
        setState(prev => ({...prev, [obj.state]: true}));
      }
    }));
  };

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };

  useEffect(() => {
    onRouteChanged();

    const body = document.querySelector('body');
    const handleMouseOver = (el) => {
      if(body.classList.contains('sidebar-icon-only')) {
        el.classList.add('hover-open');
      }
    };
    const handleMouseOut = (el) => {
      if(body.classList.contains('sidebar-icon-only')) {
        el.classList.remove('hover-open');
      }
    };

    const navItems = document.querySelectorAll('.sidebar .nav-item');
    navItems.forEach((el) => {
      el.addEventListener('mouseover', () => handleMouseOver(el));
      el.addEventListener('mouseout', () => handleMouseOut(el));
    });

    return () => {
      navItems.forEach((el) => {
        el.removeEventListener('mouseover', () => handleMouseOver(el));
        el.removeEventListener('mouseout', () => handleMouseOut(el));
      });
    };
  }, [location]);

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className="nav-item nav-category"><Trans>Main</Trans></li>
        <li className={ isPathActive('/dashboard') ? 'nav-item active' : 'nav-item' }>
          <Link className="nav-link" to="/dashboard">
            <span className="icon-bg"><i className="mdi mdi-cube menu-icon"></i></span>
            <span className="menu-title"><Trans>Dashboard</Trans></span>
          </Link>
        </li>
        <li className="nav-item nav-category"><Trans>Accounting Module</Trans></li>
        <li className={ isPathActive('/accounting') ? 'nav-item active' : 'nav-item' }>
          <div className={ state.accountingMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } 
               onClick={ () => toggleMenuState('accountingMenuOpen') } 
               data-toggle="collapse">
            <span className="icon-bg"><i className="mdi mdi-crosshairs-gps menu-icon"></i></span>
            <span className="menu-title"><Trans>Accounting</Trans></span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={ state.accountingMenuOpen }>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> 
                <Link className={ isPathActive('/accounting/income') ? 'nav-link active' : 'nav-link' } 
                      to="/accounting/income">
                  <Trans>Income</Trans>
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>
        <li className={ isPathActive('/dashboard') ? 'nav-item active' : 'nav-item' }>
          <Link className="nav-link" to="/dashboard">
            <span className="icon-bg"><i className="mdi mdi-cube menu-icon"></i></span>
            <span className="menu-title"><Trans>Notifications</Trans></span>
          </Link>
        </li>
        <li className={ isPathActive('/dashboard') ? 'nav-item active' : 'nav-item' }>
          <Link className="nav-link" to="/dashboard">
            <span className="icon-bg"><i className="mdi mdi-cube menu-icon"></i></span>
            <span className="menu-title"><Trans>Mail</Trans></span>
          </Link>
        </li>
        <li className={ isPathActive('/dashboard') ? 'nav-item active' : 'nav-item' }>
          <Link className="nav-link" to="/dashboard">
            <span className="icon-bg"><i className="mdi mdi-cube menu-icon"></i></span>
            <span className="menu-title"><Trans>Reports</Trans></span>
          </Link>
        </li>
        <li className={ isPathActive('/admin') ? 'nav-item active' : 'nav-item' }>
          <div className={ state.servicesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } 
               onClick={ () => toggleMenuState('servicesMenuOpen') } 
               data-toggle="collapse">
            <span className="icon-bg"><i className="mdi mdi-crosshairs-gps menu-icon"></i></span>
            <span className="menu-title"><Trans>Services</Trans></span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={ state.servicesMenuOpen }>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> 
                <Link className={ isPathActive('/admin/services') ? 'nav-link active' : 'nav-link' } 
                      to="/admin/services">
                  <Trans>Services</Trans>
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>
        <li className="nav-item nav-category"><Trans>Admin Module</Trans></li>
        <li className={ isPathActive('/admin') ? 'nav-item active' : 'nav-item' }>
          <div className={ state.Settings ? 'nav-link menu-expanded' : 'nav-link' } 
               onClick={ () => toggleMenuState('Settings') } 
               data-toggle="collapse">
            <span className="icon-bg"><i className="mdi mdi-crosshairs-gps menu-icon"></i></span>
            <span className="menu-title"><Trans>Settings</Trans></span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={ state.Settings }>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> 
                <Link className={ isPathActive('/admin/services') ? 'nav-link active' : 'nav-link' } 
                      to="/admin/services">
                  <Trans>Theme</Trans>
                </Link>
                <Link className={ isPathActive('/admin/services') ? 'nav-link active' : 'nav-link' } 
                      to="/admin/services">
                  <Trans>Profile</Trans>
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;