import React, { Component, Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Spinner from "../app/shared/Spinner";
import ProtectedRoute from "./ProtectedRoute";

// Lazy loading of components
const Index = lazy(() => import("./user-pages/Index"));
const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const Accounting = lazy(() => import("./dashboard/Accounting"));
const Services = lazy(() => import("./dashboard/Services"));
const Login = lazy(() => import("./user-pages/Login"));
const Register = lazy(() => import("./user-pages/Register"));
const BasicTable = lazy(() => import("./tables/BasicTable"));
const Mdi = lazy(() => import("./icons/Mdi"));
const ChartJs = lazy(() => import("./charts/ChartJs"));
const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Index />} />
          <Route path="/admin/login" element={<Login />} />
          <Route path="/admin/register" element={<Register />} />

          {/* Protected Routes */}
          <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
          <Route
            path="/accounting/income"
            element={<ProtectedRoute element={<Accounting />} />}
          />
          <Route
            path="/admin/services"
            element={<ProtectedRoute element={<Services />} />}
          />
          <Route
            path="/tables/basic-table"
            element={<ProtectedRoute element={<BasicTable />} />}
          />
          <Route path="/icons/mdi" element={<ProtectedRoute element={<Mdi />} />} />
          <Route path="/charts/chart-js" element={<ProtectedRoute element={<ChartJs />} />} />

          {/* Error Pages */}
          <Route path="/error-pages/error-404" element={<Error404 />} />
          <Route path="/error-pages/error-500" element={<Error500 />} />

          {/* Redirect to 404 for unmatched routes */}
          <Route path="*" element={<Navigate to="/error-pages/error-404" />} />
        </Routes>
      </Suspense>
    );
  }
}

export default AppRoutes;
